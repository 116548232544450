<template>
  <div>
    <b-overlay
      :show="loadingEmpresa"
      rounded="lg"
      opacity="0.6"
    >
      <b-card>
        <h1
          class="mb-1"
        >
          Painel Planeja Saúde
        </h1>
        <div
          v-if="reports.length > 1"
        >
          <b-form-group

            label="Selecione um relatório"
            label-for="report-select"
          >
            <v-select
              id="report-select"
              v-model="reportSelected"
              :options="reports"
              placeholder="Todos"
              :clearable="false"
              label="descricao"
              item-text="descricao"
              item-value="id_relatorio_externo_acesso_valor_usuario"
              :reduce="option => option.id_relatorio_externo_acesso_valor_usuario"
              @input="prepareReport"
            >
              <span slot="no-options">Nenhuma opção selecionável.</span>
            </v-select>
          </b-form-group>
          <div class="my-2">
            <b-overlay
              :show="loadingReport"
              rounded="lg"
              opacity="1"
            >
              <PowerBIReport
                v-if="!loadingReport && embedConfig.accessToken"
                :embed-config="embedConfig"
              />
            </b-overlay>
          </div>
        </div>
        <div
          v-else
          class="my-2"
        >
          <b-overlay
            :show="loadingReport"
            rounded="lg"
            opacity="1"
          >
            <PowerBIReport
              v-if="!loadingReport && embedConfig.accessToken"
              :embed-config="embedConfig"
            />
          </b-overlay>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { BCard, BFormGroup, BOverlay } from "bootstrap-vue";
import PowerBIReport from "@/views/components/power-bi-embedded/PowerBIReport.vue";
import vSelect from 'vue-select'
import { modalGenericFillableErrorWithReload, modalGenericModel } from '@/libs/sweetalerts'

export default {
  components: {
    PowerBIReport,
    BCard,
    vSelect,
    BFormGroup,
    BOverlay
  },
  data() {
    return {
      embedConfig: {
        groupId: null,
        reportId: null,
        accessToken: null
      },
      loadingEmpresa: true,
      loadingReport: false,
      reports: [],
      reportSelected: null,
      idTipoRelatorio: 2
    };
  },

  mounted() {
    this.getReports(2)
  },

  methods: {
    getReports(idModule) {
      this.$http.get(this.$api.getReportsByModule(idModule))
        .then(res => {
          this.reports = res?.data && res.data !== '' ? res.data : []
          this.automaticRender()
        })
        .catch(() =>
          modalGenericFillableErrorWithReload(
            "Ocorreu um erro inesperado, tente novamente mais tarde ou entre em contato com o suporte."
          )
        )
    },
    prepareReport(accessValueExternalReportId) {
      this.loadingReport = true
      this.$http.get(this.$api.accessPowerBiReport(accessValueExternalReportId, this.idTipoRelatorio))
        .then(res => {
          if(res?.data){
            this.embedConfig.groupId = res.data.groupId;
            this.embedConfig.reportId = res.data.reportId;
            this.embedConfig.accessToken = res.data.accessToken;
            this.loadingReport = false
          }
        })
        .catch(error => {
          if (error?.response?.status === 404) {
            modalGenericFillableErrorWithReload(
              "O relatório solicitado não pôde ser encontrado. Verifique se os dados do relatório foram salvos corretamente e tente novamente. Caso precise de ajuda, entre em contato com o suporte técnico para obter assistência."
            );
          }else{
            modalGenericFillableErrorWithReload(
              "Desculpe, ocorreu um erro inesperado na aplicação. Por favor, tente novamente mais tarde ou entre em contato com o suporte técnico para obter assistência."
            );
          }
        })
    },
    automaticRender(){
      if(this.reports.length === 1){
        this.prepareReport(this.reports[0].id_relatorio_externo_acesso_valor_usuario);
      }else if(this.reports.length === 0){
        return modalGenericModel(
          'Erro',
          'Seu usuário não possui painéis para visualização. Tente novamente mais tarde ou entre em contato com o suporte técnico.',
          require('@/assets/custom-icons/cora-icons/error.png'),
          'Ok'
        ).then(() => {
          this.$router.push({ name: 'bem-vindo' });
        });
      }
      this.loadingEmpresa = false
    }
  }
};
</script>
